import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { db, getDoc, doc } from "@/config/firebaseInit.js";

const getDefaultState = () => {
  return {
    user: {},
    showInvertedLogo: false,
    bgImage: require("@/assets/images/dis_background.jpg"),
  };
};

const store = createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    user: {},
    showInvertedLogo: false,
    bgImage: require("@/assets/images/dis_background.jpg"),
  },
  mutations: {
    saveUser(state, data) {
      state.user = data;
    },
    clearUser(state) {
      state = Object.assign(state, getDefaultState());
    },
    setInvertedImage(state, data) {
      state.showInvertedLogo = data;
    },
    setBackgroundImage(state, data) {
      state.bgImage = data;
    },
  },
  actions: {
    signOut(context) {
      context.commit("clearUser");
    },

    getUserData(context, id) {
      return new Promise((resolve, reject) => {
        try {
          const companyRef = doc(db, "companies", id);
          return getDoc(companyRef)
            .then((res) => {
              if (!res.exists()) return resolve({ userExists: false });
              const data = Object.assign({ id: res.id }, res.data());
              if (data.adPortalBgImage && data.adPortalBgImage.url) {
                context.commit("setBackgroundImage", data.adPortalBgImage.url);
                context.commit("setInvertedImage", true);
              }
              context.commit("saveUser", data);
              return resolve({ userExists: true });
            })
            .catch((err) => {
              throw err;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  modules: {},
});

export default store;
