<template>
  <div class="title logo">
    <img
      width="500"
      src="@/assets/images/logo.png"
      alt=""
      srcset=""
      :class="{ invertImage: this.$store.state.showInvertedLogo }"
    />
  </div>
  <div class="user-header">
    <div
      v-if="Object.keys($store.state).length && $store.state.user.images.url"
      class="welcome-text"
    >
      <h3>Welcome</h3>
      <img
        max-height="30px"
        :src="$store.state.user.images.url"
        style="max-height: 40px; padding-left: 25px"
      />
    </div>
    <h3 v-else>Welcome {{ $store.state.user.name }}</h3>
    <a class="hover-underline-animation" @click="logout">Logout</a>
  </div>
</template>

<script>
import { auth, signOut } from "@/config/firebaseInit.js";

export default {
  name: "Header",
  methods: {
    logout() {
      signOut(auth)
        .then(() => {
          this.$store.dispatch("signOut").then(() => {
            this.$router.push({
              name: "Login",
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
