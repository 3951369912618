<template>
  <img class="background-image" :src="$store.state.bgImage" />
  <div class="background">
    <Header v-if="$route.name !== 'LogIn' && $route.name !== 'ResetPassword' && $route.name !== 'Registration' && $store.state.user.id" />
    <router-view />
  </div>
</template>

<script>
import Header from "@/views/Header.vue";

export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>
